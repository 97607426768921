import React from "react"

import { withContactHeader } from "./withContactHeader"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { HeroItem } from "../../Sections/Hero/Item/HeroItem"

export const ContactHeader = withContactHeader(
  ({ banner, page }): JSX.Element => (
    <>
      <HeroItem item={banner} size={`medium`} />
      <Breadcrumbs page={page} />
    </>
  )
)
