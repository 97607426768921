import React, { memo, useMemo } from "react"

import { useDom } from "../../../../hooks/useDom"
import { useTemplate } from "../../../../hooks/useTemplate"

export const withHeroItem = Component =>
  memo(({ name = "HeroItem", homepage, item, handleLogout, handlePromoClick, size = `large`, tag, top }: any) => {
    const { dom } = useDom()
    const { global: locales } = useTemplate()

    const ratios = useMemo(
      () => ({
        desktop: size === `medium` ? 90 / 23 : 90 / 49,
        mobile: size === `medium` ? 75 / 64 : 125 / 208,
      }),
      [size]
    )
    const ratiosRevert = useMemo(
      () => ({
        desktop: size === `medium` ? 32 / 90 : 49 / 90,
        mobile: size === `medium` ? 64 / 75 : 172 / 125,
      }),
      [size]
    )
    const theme = useMemo(
      () => ({
        background: item?.background?.toLowerCase()?.replace(/ /g, `-`) || `purple-dark`,
        button: item?.button?.toLowerCase() || `orange`,
        buttonSize: item?.buttonSize || `primary`,
        colour: item?.text?.toLowerCase()?.replace(`light`, `white`) || `white`,
        size,
      }),
      [item, size]
    )
    const heights = {
      desktop: dom?.width / ratios?.desktop || null,
      mobile: dom?.width / ratios?.mobile || null,
    }

    const image = dom?.isMedium ? item?.image?.desktop : item?.image?.mobile
    const height = dom?.isLarge ? heights?.desktop : heights?.mobile

    // Will use desktop video and padding ratio by default if mobile video is undefined
    const video = !heights.desktop && !heights.mobile ? null : dom?.isMedium ? item?.video?.desktop : item?.video?.mobile || item?.video?.desktop
    const paddingRatio = dom?.isMedium || !item?.video?.mobile ? ratiosRevert.desktop : ratiosRevert.mobile

    // define image ratio from internal image dimension
    const imageRatio = (image?.height / image?.width) * 100 || 100
    let videoRatio = paddingRatio * 100
    // use ratio from user defined video width/height
    if (video?.width && video?.height) {
      videoRatio = (video.height / video.width) * 100
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          height={height}
          paddingRatio={paddingRatio}
          imageRatio={`${imageRatio.toFixed(2)}%`}
          videoRatio={`${videoRatio.toFixed(2)}%`}
          homepage={homepage}
          image={image}
          video={video}
          item={item}
          handleLogout={handleLogout}
          handlePromoClick={handlePromoClick}
          locales={locales}
          tag={tag}
          theme={theme}
          top={top}
        />
      ),
      [height, paddingRatio, homepage, image, video, item, handleLogout, handlePromoClick, locales, tag, theme, top]
    )
  })
