import React from "react"

import { withContactForm } from "./withContactForm"
import { FormInput } from "../../Form/Input/FormInput"
import { FormSelect } from "../../Form/Select/FormSelect"
import { FormTextarea } from "../../Form/Textarea/FormTextarea"
import { P } from "../../Styled/Text"
import { Form, Button } from "./styledContactForm"

export const ContactForm = withContactForm(
  ({ form, errors, data, handleChange, handleSubmit, loading, locales, success }): JSX.Element => (
    <>
      {success ? (
        <P as={`p`} large colour={`purple-dark`}>
          {locales?.additionalSuccess}
        </P>
      ) : (
        <Form onSubmit={handleSubmit}>
          <FormInput
            autoFocus
            name={`name`}
            type={`text`}
            full
            onChange={handleChange}
            placeholder={locales?.additionalName}
            required
            value={data?.name}
            withSpacing={`xs`}
          />
          <FormInput
            name={`email`}
            type={`email`}
            full
            onChange={handleChange}
            placeholder={locales?.additionalEmail}
            required
            value={data?.email}
            withSpacing={`xs`}
          />
          {form?.types?.length > 1 && (
            <FormSelect
              name={`type`}
              full
              onChange={handleChange}
              options={form?.types?.map(({ id, title }) => ({ label: title, value: id }))}
              placeholder={locales?.additionalSubject}
              required
              value={data?.type}
              withSpacing={`xs`}
            />
          )}
          <FormTextarea
            name={`message`}
            errors={errors}
            full
            onChange={handleChange}
            placeholder={locales?.additionalMessage}
            required
            value={data?.message}
            withSpacing={`lg`}
          />
          <Button type={`submit`} disabled={loading} colour={`purple`} size={`primary`} title={locales?.additionalSubmit}>
            {locales?.additionalSubmit}
          </Button>
        </Form>
      )}
    </>
  )
)
