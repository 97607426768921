import React from "react"

import { withFormSelect } from "./withFormSelect"
import { Icon } from "../../Icon/Icon"
import { StyledErrors, StyledSelectWrapper, StyledSelect, StyledSelectOption, StyledLabel, StyledSrLabel } from "../../Styled/Form"

export const FormSelect = withFormSelect(
  ({ errors, field, full, id, label, options, placeholder, value, withSpacing, colour }): JSX.Element => (
    <>
      <StyledSelectWrapper full={full} withSpacing={!(errors?.length > 0) && withSpacing}>
        <StyledSrLabel htmlFor={id}>{placeholder}</StyledSrLabel>
        <StyledSelect {...field} errors={errors} id={id} value={value} name={label}>
          {placeholder && (
            <StyledSelectOption disabled value={``}>
              {placeholder}
            </StyledSelectOption>
          )}
          {options?.map(({ label, value, ...option }, index: string) => (
            <StyledSelectOption {...option} key={value || index?.toString()} value={value}>
              {label || value}
            </StyledSelectOption>
          ))}
        </StyledSelect>
        {placeholder && (
          <div>
            <StyledLabel>{placeholder}</StyledLabel>
          </div>
        )}
        <Icon name={`down`} colour={colour} />
      </StyledSelectWrapper>
      {errors?.length > 0 && (
        <>
          {errors?.map(({ code, message }) => (
            <StyledErrors key={code?.toString()} withSpacing={withSpacing}>
              {message?.toString()}
            </StyledErrors>
          ))}
        </>
      )}
    </>
  )
)
