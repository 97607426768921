import React, { memo, useMemo } from "react"

export const withFormSelect = Component =>
  memo(
    ({
      name = "FormSelect",
      errors = null,
      full = false,
      id: formId = null,
      name: label,
      options,
      placeholder,
      value = ``,
      withSpacing = null,
      colour = "purple-dark",
      ...field
    }: any) => {
      const id = formId || `${label}_${Math.random().toString(36).substr(2, 9)}`

      Component.displayName = name
      return useMemo(
        () => (
          <Component
            errors={errors}
            field={field}
            full={full}
            id={id}
            label={label}
            options={options}
            placeholder={placeholder}
            value={value}
            withSpacing={withSpacing}
            colour={colour}
          />
        ),
        [errors, field, full, id, label, options, placeholder, withSpacing, value, colour]
      )
    }
  )
