import React from "react"
import { graphql } from "gatsby"

import { Contact as Page } from "../components/Contact/Contact"

export const query = graphql`
  query {
    page: sanityPageContact {
      title
      bannerBackground
      bannerImage: _rawBannerImage(resolveReferences: { maxDepth: 2 })
      items: _rawItems(resolveReferences: { maxDepth: 4 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      form
      additionalName
      additionalForm
      additionalFooter
      additionalEmail
      additionalSubject
      additionalMessage
      additionalSubmit
      additionalSuccess
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
