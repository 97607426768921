import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withHeroItem } from "./withHeroItem"
import {
  Slide,
  Link,
  Logout,
  Image,
  Content,
  Text,
  Title,
  Subtitle,
  Subcontent,
  Button,
  Container,
  StyledVideoWrapper,
  StyledVideo,
} from "./styledHeroItem"

export const HeroItem = withHeroItem(
  ({ height, imageRatio, videoRatio, homepage, image, video, item, handleLogout, handlePromoClick, locales, tag, theme, top }): JSX.Element => (
    <Slide colour={theme?.background}>
      <Link as={item?.link?.url ? GatsbyLink : null} onClick={handlePromoClick} to={item?.link?.url}>
        {theme?.size !== `small` && !video && <Image background={image?.src} top={top} ratio={imageRatio} />}
        {theme?.size !== `small` && video && (
          <StyledVideoWrapper background={image?.src} ratio={videoRatio}>
            <StyledVideo url={video.link} playing loop playsinline={true} muted volume={0} width={`100%`} height={`100%`} />
          </StyledVideoWrapper>
        )}
        <Content theme={theme?.size}>
          <Container width={`xl`}>
            <Text position={item?.position} size={item?.size} theme={theme?.size}>
              <div>
                {item?.title && (
                  <Title
                    as={homepage ? tag : item?.size !== `large` ? `h2` : null}
                    colour={theme?.colour}
                    size={item?.size}
                    theme={theme?.size}
                    withSpacing={item?.content || item?.subcontent || item?.link?.title ? `sm` : null}
                  >
                    {item?.title}
                  </Title>
                )}

                {item?.content && (
                  <Subtitle
                    as={item?.size !== `large` || homepage ? `p` : null}
                    colour={theme?.colour}
                    size={item?.size}
                    theme={theme?.size}
                    withSpacing={item?.subcontent ? `xxl` : item?.link?.title ? `md` : null}
                    dense={item?.dense ? "true" : undefined}
                  >
                    {item?.content}
                  </Subtitle>
                )}
              </div>
              {item?.subcontent && (
                <Subcontent
                  as={homepage ? `p` : null}
                  colour={theme?.colour}
                  large={item?.large}
                  position={theme?.alignment}
                  withSpacing={item?.link?.title ? `md` : null}
                >
                  {item?.subcontent}
                </Subcontent>
              )}
              {item?.link?.title && (
                <Button colour={theme?.button} full={item?.position !== `middle` ? `true` : null} size={theme?.buttonSize}>
                  {item?.link?.title}
                </Button>
              )}
            </Text>
            {handleLogout && (
              <Logout as={`button`} onClick={handleLogout} title={locales?.additionalLogout}>
                {locales?.additionalLogout}
              </Logout>
            )}
          </Container>
        </Content>
      </Link>
    </Slide>
  )
)
