import React from "react"

import { withContact } from "./withContact"
import { Accordion } from "../Accordion/Accordion"
import { ContactHeader } from "./Header/ContactHeader"
import { StyledContainer } from "../Styled/Container"
import { Page, Title, Subtitle } from "./styledContact"

export const Contact = withContact(
  ({ items, page }): JSX.Element => (
    <>
      <ContactHeader page={page} />
      <Page>
        <StyledContainer width={`lg`}>
          <Title as={`h1`} align={`center`} colour={`orange`}>
            {page?.title}
          </Title>
          <Accordion items={items} />
          <Subtitle as={`h2`} align={`center`} colour={`orange`}>
            {page?.additionalFooter}
          </Subtitle>
        </StyledContainer>
      </Page>
    </>
  )
)
