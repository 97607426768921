import React, { memo, useMemo } from "react"

import { useCore } from "../../hooks/useCore"
import { ContactForm } from "./Form/ContactForm"
import { RichText } from "../Styled/Text"

export const withContact = Component =>
  memo(({ name = "Contact", page }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const items = useMemo(
      () =>
        [
          ...(page?.items?.map((item: any) => ({
            ...item,
            children: item?.content ? (
              <RichText colour={`grey-darker`} large>
                {sanityContent(item?.content)}
              </RichText>
            ) : null,
          })) || []),
          page?.form
            ? {
                title: page?.additionalForm,
                children: <ContactForm page={page} />,
              }
            : null,
        ]?.filter(Boolean),
      [page, sanityContent]
    )

    Component.displayName = name
    return useMemo(() => <Component items={items} page={page} />, [items, page])
  })
