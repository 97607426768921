import React, { memo, useMemo } from "react"

import { useImage } from "../../../hooks/useImage"

export const withContactHeader = Component =>
  memo(({ name = "ContactHeader", page }: any) => {
    const { getResponsiveImage } = useImage()

    const banner = useMemo(
      () => ({
        background: page?.bannerBackground,
        image: getResponsiveImage(page?.bannerImage, { desktop: { maxWidth: 2000 }, mobile: { maxWidth: 800 } }),
      }),
      [getResponsiveImage, page]
    )

    Component.displayName = name
    return useMemo(() => <Component banner={banner} page={page} />, [banner, page])
  })
