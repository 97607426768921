import React, { memo, useCallback, useMemo } from "react"

import { useForm } from "../../../hooks/useForm"

export const withContactForm = Component =>
  memo(({ name = "ContactForm", page }: any) => {
    const { getForm, submitForm, data, setData, loading, errors, success } = useForm()

    const form = getForm(`contact`)
    const type = data?.type ? form?.types[data?.type?.toLowerCase()] : Object?.values(form?.types || {})[0]
    const options = type?.options

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()
        await submitForm(form, data)
      },
      [data, form, submitForm]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          data={data}
          errors={errors}
          form={form}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          locales={page}
          options={options}
          success={success}
        />
      ),
      [data, errors, form, handleChange, handleSubmit, loading, page, options, success]
    )
  })
